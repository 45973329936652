@font-face {
  font-family: VerizonNHGTX;
  src: url('./VerizonNHGTX-Regular.eot');
  src: url('./VerizonNHGTX-Regular.eot?#iefix') format('embedded-opentype'),
    url('./VerizonNHGTX-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: VerizonNHGTX;
  src: url('./VerizonNHGTX-Bold.eot');
  src: url('./VerizonNHGTX-Bold.eot?#iefix') format('embedded-opentype'),
    url('./VerizonNHGTX-Bold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: VerizonNHGTX;
  src: url('./VerizonNHGTX-Italic.eot');
  src: url('./VerizonNHGTX-Italic.eot?#iefix') format('embedded-opentype'),
    url('./VerizonNHGTX-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: VerizonNHGTX;
  src: url('./VerizonNHGTX-BoldItalic.eot');
  src: url('./VerizonNHGTX-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./VerizonNHGTX-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
