html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-size: unset !important;
  line-height: unset !important;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Apercu-Regular-Pro';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,
.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
